@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Poppins:wght@400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Roobert;
  src: url(/static/media/Roobert-Bold.f845a305.ttf);
}

:root {
  --primary-color: #0478ed;
  --secondary-color: #1c3fa8;
  --dark-color: #0f0f0f;
  --light-color: #f4f4f4;
  --white-color: #fff;
  --success-color: #5cb85c;
  --danger-color: #d9534f;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  background-color: #0f0f0f;
  background-color: var(--dark-color);
  color: #fff;
  color: var(--white-color);
  scroll-behavior: smooth;
}

body {
  font-family: 'Poppins', sans-serif;
}

.btn {
  outline: none;
  border: none;
  border-radius: 10px;
  padding: 15px 25px;
  font-size: 15px;
  font-family: inherit;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.25s;
}

.btn:hover {
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
}

.btn:active {
  -webkit-transform: translateY(2px);
  transform: translateY(2px);
}

.btn-primary {
  background-color: #6d5cd3;
  color: #fff;
}

.btn-secondary {
  background-color: #1e9dd6;
  color: #fff;
}

.btn-dark {
  background-color: #353535;
  color: #fff;
}

.btn-linear {
  background: transparent url("/static/media/Path 2542.c3cd3ba7.svg") center center;
  padding: 15px 40px;
  font-size: 20px;
}

.navbar {
  display: flex;
  padding: 21px 55px 0px 60px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navbar img {
  width: 150px;
}
.banner {
  background: url(/static/media/map.5aada13f.svg) no-repeat center;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-origin: content-box;
  background-size: 90% 90%;
}

.map__image {
  width: 90%;
}

.banner_container {
  position: relative;
  overflow: hidden;
}

.circle-1 {
  position: absolute;
  top: 50px;
  left: 70%;
  width: 23px;
  height: 23px;
  background: #1681a0;
  opacity: 1;
  border-radius: 50%;
}

.circle-2 {
  position: absolute;
  bottom: 120px;
  width: 31px;
  left: 10%;
  height: 31px;
  background: #2979c9;
  border-radius: 50%;
  opacity: 1;
}

.circle-3 {
  position: absolute;
  bottom: 100px;
  right: 20%;
  width: 31px;
  height: 31px;
  position: absolute;
  background: #aa2e84;
  border-radius: 50%;
  opacity: 1;
}

.circle-4 {
  width: 70px;
  height: 70px;
  position: absolute;
  padding: 10px;
  border-radius: 50%;
  opacity: 1;
  top: -27px;
  left: -38px;
  z-index: -1 !important;
}

.circle-4 img {
  width: 70px;
  height: 70px;
}

.circle-1,
.circle-2,
.circle-3 {
  z-index: -2;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
  overflow: auto;
  padding: 0 40px;
}

.banner-text {
  text-align: center;
  /* max-width: 750px;
  margin-top: 80px;
  z-index: 2; */
  display: flex;
  max-width: 750px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
}
.banner h2 {
  font-size: 50px;
  line-height: 1.1;
  font-family: Roobert, sans-serif;
}
.banner p {
  margin-top: 20px;
  font-size: 22px;
  margin-left: 20px;
  max-width: 600px;
}

.nft_claimed {
  position: absolute;
  top: 70px;
  left: -30px;
  background: url(/static/media/robo.2610cc6d.png) no-repeat center / cover;
  width: 200px;
  height: 200px;
  border-radius: 20px;
  display: flex;
  justify-content: end;
  align-items: center;
  flex-direction: column;
}

.nft_claimed button {
  border: none;
  padding: 10px 30px;
  background-color: #0f0f0f;
  color: #fff;
  font-family: 'Poppins';
  font-size: 15px;
  font-weight: 600;
  border-radius: 10px;
  margin-bottom: 20px;
}
/* .nft_claimed img {
  width: 250px;
} */

.eth_deposit {
  position: absolute;
  bottom: 0px;
  left: 30%;
  width: 190px;
  height: 220px;
  border-radius: 20px;
  backdrop-filter: blur(9px);
  background: #fff;
  -webkit-backdrop-filter: blur(9px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.eth_deposit button {
  border: none;
  padding: 10px 30px;
  background-color: #0f0f0f;
  color: #fff;
  font-family: 'Poppins';
  font-size: 15px;
  font-weight: 600;
  border-radius: 10px;
  margin-bottom: 15px;
}

.eth_deposit .address_card {
  position: absolute;
  right: -117px;
  background-color: #161616;
  padding: 10px;
  top: 10px;
  border-radius: 10px;
}

.eth_deposit .address_card p {
  font-size: 10px;
  margin-top: 0px;
  margin-left: 4px;
}

.eth_deposit .address_card img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.nft_claimed .address_card {
  position: absolute;
  right: -117px;
  background-color: #161616;
  padding: 10px;
  top: 10px;
  border-radius: 10px;
}

.nft_claimed .address_card p {
  font-size: 10px;
  margin-top: 0px;
  margin-left: 4px;
}

.nft_claimed .address_card img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.glass {
  background: white;
  background: linear-gradient(
    to right bottom,
    rgb(255 255 255 / 21%),
    rgb(255 255 255 / 21%)
  );
  border-radius: 2rem;
  z-index: 2;
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
}

.hand_img_container {
  position: absolute;
  right: 0px;
}
.hand_img_container img {
  width: 300px;
  margin-top: 20%;
}

.banner_image img {
  position: absolute;
  right: -10px;
  height: 650px;
}

.color-purple {
  color: #6d5cd3;
}

.text-underline {
  text-decoration: underline;
}
.salt-container {
  /* background: rgb(109, 92, 211);
  background: linear-gradient(
    180deg,
    rgba(109, 92, 211, 1) 42%,
    rgba(109, 92, 211, 0.025751194032300417) 90%
  );
  height: 80vh; */
}

.instruction__container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.instruction__container > .instruction_item {
  flex: 1 1;
}

.instruction_item img {
  width: 90%;
}

.instruction_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 30px;
}
.instruction_title h2 {
  margin-left: 10px;
}

.instruction_img {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.instruction_item a {
  text-decoration: underline;
  color: #fff;
}

.start {
  align-items: flex-start;
  justify-content: flex-start;
}

.instruction_title img {
  width: 100px;
}

.instruction_item h2 {
  text-align: left;
  font-size: 40px;
  font-family: Roobert, sans-serif;
}

.salt-calculate {
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.salt-calculate a {
  text-decoration: none;
  color: #fff;
}

.salt-text {
  position: absolute;
  right: 0px;
  padding: 26px 29px;
  display: flex;
  background-color: #6d5cd3;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0px;
}

.salt-text h5 {
  font-size: 16px;
}

.salt-line {
  width: 52px;
  height: 2px;
  background: #fff;
}

.salt-container {
  display: grid;
  place-items: center;
  height: 100vh;
}

.salt-innner-container {
  border-radius: 20px;
  height: 80%;
  position: relative;
  width: 90%;
  overflow: hidden;
}

.salt-circle1 {
  position: absolute;
  top: 70px;
  left: -320px;
  background-color: #1e9dd6;
  width: 550px;
  height: 550px;
  border-radius: 50%;
}

.salt-circle2 {
  position: absolute;
  top: 70px;
  right: -320px;
  background-color: #6d5cd3;
  width: 550px;
  height: 550px;
  border-radius: 50%;
}

.salt-line-bg {
  top: 1144px;
  left: 106px;
  width: 1119px;
  height: 421px;
  /* UI Properties */
  background: transparent url("/static/media/Path 2541.43579906.svg") 0% 0% no-repeat
    padding-box;
  opacity: 1;
}

.salt-circle-2 {
  position: absolute;
  top: 20px;
  right: 150px;
}

.salt-circle-3 {
  position: absolute;
  top: 20px;
  right: 50px;
}

.salt-calculate-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  height: 100%;
  padding: 30px 130px;
}

.salt-button-container {
  background-color: #151515;
  z-index: 100;
  width: 390px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.salt-button-container h4 {
  font-size: 20px;
  max-width: 300px;
  margin-top: 30px;
  text-align: center;
}

.salt-button-container a {
  text-decoration: underline;
  color: #6d5cd3;
  position: absolute;
  bottom: 30px;
  font-size: 15px;
  font-weight: 700;
}
.salt-result-container a {
  text-decoration: underline;
  color: #6d5cd3;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 700;
}
.salt-result-container {
  flex: 1 1;
  margin-left: 60px;
  text-align: center;
  position: relative;
  height: 100%;
  background-color: #151515;
  border-radius: 20px;
}

.salt-result-container {
  z-index: 100;
}

.absolute_center {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.absolute_center_30 {
  position: absolute;
  left: 40%;
  top: 15%;
  -webkit-transform: translate(-35%, -35%);
  transform: translate(-35%, -35%);
}

.salt-result-container .emoji {
  font-size: 150px;
}

.result-number {
  font-size: 100px;
  font-weight: bold;
}

.result_container {
  padding: 40px;
}
.result_container .color-purple {
  font-size: 25px;
}

.salt-result-container .color-purple {
  font-size: 25px;
}

.leader_board {
  height: 100%;
}

.marquee-item {
  background-color: #151515;
  border-radius: 20px;
  margin-left: 30px;
  padding: 30px;
}
.leader-title {
  margin: 45px;
}

.overlay::before,
.overlay::after {
  width: 200px !important;
}

.trophy_image {
  position: absolute;
  z-index: 100;
  right: -120px;
  top: 0px;
}
.trophy_image img {
  /* width: 100%; */
  /* height: 550px; */
  height: 470px;
}

.accordion {
  width: 50%;
  font-family: 'Poppins';
  margin-left: 60px;
  margin-bottom: 60px;
}

.accordian_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.accordion:nth-of-type(2) {
  margin-left: 20px;
}
.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  /* background-color: #6d5cd3; */
  color: #fff;
  font-weight: 700;
  /* border-radius: 20px; */
  margin-top: 20px;
  font-size: 19px;
  border-bottom: 1px solid #353535;
}
.active_symbol {
  font-size: 18px;
  font-weight: 600;
}
.accordion-title:hover {
  opacity: 0.7;
}

.accordion-content,
.accordion-title {
  padding: 1rem;
}
.accordion-content {
  font-weight: 500;
}
.accordion-content {
  background-color: #151515;
  /* border-radius: 20px; */
}

.bg-faq-bg-line {
  position: absolute;
  top: 10px;
  right: 20px;
}

.bg-faq-bg-line img {
  height: 390px;
}

.footer {
  background-color: #151515;
  padding-top: 60px;
  padding-bottom: 60px;
  z-index: 100;
  overflow: hidden;
}

.footer__row {
  display: flex;
  margin-right: -40px;
  margin-bottom: 73px;
}

.footer__col {
  flex: 0 0 25%;
  padding-right: 30px;
}

.footer__col:not(:first-child) {
  padding-top: 8px;
}

.footer__logo {
  display: inline-block;
  font-size: 0;
}

.footer__logo .footer__pic {
  width: 145px;
}

.footer__category {
  margin-bottom: 50px;
  color: #fff;
}

.footer__category .icon {
  display: none;
}

.footer__col.active .footer__category {
  border-color: #6d5cd3;
  color: #6d5cd3;
}

.footer__col.active .footer__category .icon {
  fill: #6d5cd3;
}

.footer__menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #fff;
}

.footer__link {
  color: #fff;
  transition: color 0.25s;
  text-decoration: none;
}

.footer__link:hover {
  color: #6d5cd3;
}

.footer__link:not(:last-child) {
  margin-bottom: 16px;
}

.footer__bottom {
  display: flex;
  align-items: center;
  padding: 26px 0 50px;
  border-top: 1px solid #e3e6e9;
}

.footer__company {
  margin-right: 16px;
  font-size: 0;
}

.footer__copyright {
  margin-right: auto;
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}

.footer__scroll {
  display: flex;
  align-items: center;
  margin-left: auto;
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  transition: opacity 0.25s;
}

.footer__scroll:hover {
  opacity: 0.85;
}

.footer__icon {
  margin-right: 16px;
  font-size: 0;
}

.trophy__mobile {
  display: none;
}

/* STEPPER  */

.register-stepper {
  display: table;
  width: 100%;
  margin: 0 auto;
}
.register-stepper .step {
  display: table-cell;
  position: relative;
  padding: 1.5rem;
  z-index: 2;
  width: 25%;
}
.register-stepper .step:last-child .step-bar-left,
.register-stepper .step:last-child .step-bar-right {
  display: none;
}
.register-stepper .step .step-circle {
  width: 2rem;
  height: 2rem;
  margin: 0 auto;
  border-radius: 50%;
  text-align: center;
  line-height: 1.75rem;
  font-size: 1rem;
  font-weight: 600;
  z-index: 2;
  border: 2px solid #6d5cd3;
}

.register-stepper .step.done .step-circle {
  background-color: #6d5cd3;
  border: 2px solid #6d5cd3;
  color: #ffffff;
}
.register-stepper .step.done .step-circle:before {
  font-weight: 300;
  content: '\2713';
}
.register-stepper .step.done .step-circle * {
  display: none;
}
.register-stepper .step.done .step-title {
  color: #6d5cd3;
}
.register-stepper .step.editing .step-circle {
  background: #ffffff;
  border-color: #6d5cd3;
  color: #6d5cd3;
}
.register-stepper .step.editing .step-title {
  color: #6d5cd3;
}
.register-stepper .step .step-title {
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 600;
}
.register-stepper .step .step-title {
  text-align: center;
}

.register-stepper .step .step-bar-left,
.register-stepper .step .step-bar-right {
  position: absolute;
  top: calc(2rem + 5px);
  height: 2px;
  background-color: #d9e2ec;
  border: solid #d9e2ec;
  border-width: 1px 0;
}
.register-stepper .step .step-bar-left {
  width: calc(100% - 2rem);
  left: 50%;
  margin-left: 1rem;
  z-index: -1;
}
.register-stepper .step .step-bar-right {
  width: 0;
  left: 50%;
  margin-left: 1rem;
  z-index: -1;
  transition: width 500ms ease-in-out;
}
.register-stepper .step.done .step-bar-right {
  background-color: #6d5cd3;
  border-color: #6d5cd3;
  z-index: 3;
  width: calc(100% - 2rem);
}

.sm_company_logo {
  display: none;
}

.Toastify__toast-body {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  z-index: 10000;
}
/* snap scroll  */

.snap_container {
  overflow: auto;
}

.snap_container.y {
  /* width: 100%;
  height: 100vh; */
}

.y.mandatory-scroll-snapping {
  -ms-scroll-snap-type: y mandatory;
      scroll-snap-type: y mandatory;
}

.snap_container > .snap_items {
  scroll-snap-align: start;
}

.loading_animation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.accordion ol {
  margin-left: 20px;
  margin-top: 10px;
  text-align: left;
}

.leader_board {
  overflow: hidden;
}

.salt-button-container .btn {
  display: block;
  width: 70%;
  border-radius: 5px;
}

.web3modal-modal-lightbox {
  z-index: 999 !important;
}

.instruction {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1300px;
  margin: 0 auto;
  overflow: auto;
  padding: 0 40px;
}

.instruction__container {
  margin-bottom: 100px;
}

.instruction h3 {
  font-size: 50px;
  max-width: 600px;
  text-align: center;
  font-family: Roobert, sans-serif;
}

.instruction_subtitle {
  font-size: 22px;
  max-width: 600px;
  margin-top: 20px;
  text-align: center;
  margin-bottom: 70px;
}

.salt_container_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.salt_container_text h3 {
  font-size: 50px;
  max-width: 600px;
  text-align: center;
  font-family: Roobert, sans-serif;
}

.salt_container_text p {
  font-size: 22px;
  max-width: 600px;
  margin-top: 20px;
  text-align: center;
  margin-bottom: 35px;
}

.instruction_item p {
  font-size: 22px;
  margin-top: 10px;
}

@media only screen and (max-width: 700px) {
  .navbar {
    display: flex;
    padding: 20px 10px;
  }
  .navbar img {
    width: 120px;
  }
  .banner-text {
    padding: 0 30px;
    margin-top: 25px;
    margin-bottom: 100px;
  }
  .banner {
    height: calc(100vh - 170px);
  }
  .earn_xp_section {
    margin-top: 40px;
  }
}

/* @media only screen and (max-width: 767px) {
  .footer__row {
    display: block;
    margin: 0 0 32px;
  }
  .footer__col {
    width: 100%;
    margin-bottom: 32px;
    padding: 0;
  }
  .footer__col:first-child {
    margin-bottom: 56px;
    text-align: center;
  }
  .footer__col:not(:first-child) {
    padding: 0;
  }
  .footer__category {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 1px solid #e3e6e9;
    margin: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
  }
  .footer__category .icon {
    display: inline-block;
    margin-left: auto;
    font-size: 10px;
    fill: #999fae;
    -webkit-transition: fill 0.25s;
    -o-transition: fill 0.25s;
    transition: fill 0.25s;
  }
  .footer__menu {
    display: none;
    padding-top: 24px;
  }
  .footer__link {
    display: block;
  }
  .footer__bottom {
    padding: 0 0 32px;
    border: none;
  }
  .footer__scroll {
    font-size: 0;
  }
  .footer__icon {
    margin: 0;
  }
} */

.absolute_center.leaderboard-center {
  top: 35%;
}

.share__social_icon {
  padding: 10px;
}

.instruction_wrapper {
  margin-top: 100px;
}

.crew-3xp {
  background-color: #2b2b2b;
  display: flex;
  width: 180px;
  justify-content: space-between;
  padding: 10px 15px;
  border-radius: 10px;
  align-items: center;
}

.crew-3xp-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 30px;
}

.grid_earn_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  margin-bottom: 30px;
  margin-top: 23px;
  grid-column-gap: 14px;
  -webkit-column-gap: 14px;
          column-gap: 14px;
  grid-gap: 34px;
  gap: 34px;
  margin-bottom: 70px;
  margin-top: 35px;
}

.earn_item {
  background-color: #222222;
  padding: 20px;
  height: 220px;
  border-radius: 20px;
  position: relative;
  transition: all 0.1s;
  cursor: pointer;
}

.earn_item:hover {
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
}

.earn_item h6 {
  background-color: #6d5cd3;
  border-radius: 20px;
  padding: 5px 21px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
  position: absolute;
  bottom: 25px;
}

.earn_item h1 {
  font-family: Roobert, sans-serif;
  font-size: 26px;
}

.earn_item p {
  margin-top: 10px;
  font-weight: 100;
  font-size: 15px;
}

.crew-3xp h6 {
  font-size: 15px;
}
.crew-3xp h3 {
  color: #6d5cd3;
  font-weight: bold;
  font-size: 22px;
}

@media only screen and (max-width: 900px) {
  .banner_image {
    display: none;
  }
  .banner {
    flex-direction: column;
    background: url(/static/media/map.5aada13f.svg) no-repeat center / cover;
    background-size: auto;
  }
  .salt-calculate-container {
    flex-direction: column;
  }
  .salt-calculate-container {
    padding: 10px;
  }
  .salt_container_text p {
    padding: 20px;
  }
  .nft_claimed,
  .eth_deposit,
  .hand_img_container,
  .circle-1,
  .circle-2,
  .circle-3,
  .circle-4,
  .salt-circle2 {
    display: none;
  }

  .instruction__container {
    flex-direction: column;
  }

  .instruction_title {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .crew-3xp-container {
    justify-content: center;
    align-items: center;
  }

  .instruction_title h2 {
    font-size: 20px;
    margin-left: 0px;
    text-align: center;
  }
  .accordion {
    width: 100%;
  }

  .instruction_item p {
    font-size: 16px;
    text-align: center;
  }

  .instruction_img {
    margin-bottom: 20px;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
  }
  .instruction__container {
    margin-bottom: 50px;
  }
  .instruction_wrapper {
    margin-top: 0px;
  }
  .banner {
    height: 120%;
  }

  .salt-button-container {
    width: 100%;
    /* max-width: 560px; */
  }
  .salt-result-container {
    margin-left: 0px;
    margin-top: 23px;
  }
  .salt-innner-container {
    height: 100%;
    margin-top: 30px;
  }
  .salt-innner-container.glass {
    background: none;
  }
  .salt-button-container a {
    position: relative;
    margin-top: 50px;
  }
  .salt-container {
    padding: 0px 0px 20px 0px;
  }
  .marquee-item {
    padding: 20px;
  }
  .leader-title {
    margin: 25px;
  }
  .overlay::before,
  .overlay::after {
    width: auto !important;
    width: initial !important;
  }
  .trophy_image {
    display: none;
  }
  .accordion {
    padding: 10px;
    margin-left: 0px;
  }
  .company_logo {
    display: none;
  }
  .sm_company_logo {
    display: block;
  }
  .sm_company_logo img {
    width: 145px;
    margin-bottom: 31px;
  }
  .absolute_center {
    position: relative;
    left: revert;
    top: revert;
    -webkit-transform: revert;
            transform: revert;
  }
  .result-number {
    font-size: 70px;
  }
  .banner h2 {
    font-size: 40px;
    line-height: revert;
  }
  .salt-result-container a {
    margin-top: 20px;
  }
  .banner p {
    margin-bottom: 20px;
    font-size: 18px;
  }
  .salt-button-container h4 {
    font-size: 15px;
  }
  .salt-result-container .emoji {
    font-size: 100px;
  }
  .bg-faq-bg-line {
    display: none;
  }
  .salt-result-container a {
    max-width: 100px;
  }
  .trophy__mobile {
    display: grid;
    place-items: center;
  }
  .trophy__mobile img {
    width: 63%;
    /* margin-top: 30px; */
  }
  .leader_board {
    /* margin-top: 200px; */
  }
  .snap_container.y {
    height: 100%;
  }
  .salt-container {
    height: 100%;
  }
  .absolute_center.leaderboard-center {
    top: 0px;
  }
  .leader_board {
    height: auto;
  }
  .accordion:nth-of-type(2) {
    margin-left: 0px;
  }
}

/* @media only screen and (min-width: 768px) {
  .footer__menu {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
} */

@media only screen and (max-width: 1100px) {
  .salt-circle-2,
  .salt-circle-3,
  .salt-circle1,
  .salt-circle-4,
  .salt-line-bg,
  .salt-circle2 {
    display: none;
  }
  .salt-result-container .absolute_center {
    position: relative;
    position: relative;
    left: revert;
    top: revert;
    -webkit-transform: revert;
            transform: revert;
  }
}

@media only screen and (min-width: 1700px) {
  .banner h2 {
    font-size: 80px;
  }
  .banner p {
    font-size: 30px;
  }
  .banner-text {
    max-width: 950px;
  }
  .hand_img_container img {
    width: 450px;
  }
  .nft_claimed {
    width: 250px;
    height: 250px;
  }
  .eth_deposit {
    width: 200px;
    height: 240px;
  }
  .eth_deposit button {
    margin-bottom: 10px;
  }
  .eth_deposit .address_card h6,
  .nft_claimed .address_card h6 {
    font-size: 16px;
  }
  .eth_deposit .address_card p,
  .nft_claimed .address_card p {
    font-size: 11px;
  }
  .eth_deposit .address_card img,
  .nft_claimed .address_card img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .salt-circle1,
  .salt-circle2 {
    top: 170px;
  }
  .salt-button-container {
    width: 590px;
  }
}

